<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-12 pl-9' : 'px-5'" style="min-height: 80vh;">
    <!-- ==== DIALOG SUCCESS ==== -->
    <v-dialog v-model="dialog.success" persistent width="425">
      <v-card class="border-radius box-shadow">
        <v-card-text class="py-5">
          <div class="text-center">
            <img src="@/assets/images/confirmation.png" width="250" />
            <p
              class="font-weight-bold mb-1"
              style="font-size: 1.1rem !important;"
            >
              Anda telah berhasil {{ dialog.state == 'delete' ? 'menghapus' : 'mengirimkan undangan' }} ke merchant
            </p>
            <p class="body-2 font-weight-regular">
              Silahkan klik tombol dibawah ini
            </p>
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              outlined
              color="#d31145"
              class="text-capitalize"
              @click="dialog.success = false"
            >
              Ke List
              <v-icon color="#d31145" small class="ml-1"
                >mdi-chevron-right</v-icon
              >
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <div v-html="snackbar.text"></div>
    </v-snackbar>

    <!-- ==== DIALOG DETAIL ==== -->
    <v-dialog v-model="dialog.detail" persistent width="450">
      <v-card class="border-radius box-shadow">
        <v-card-title
          class="body-2 white--text"
          style="background-color: #d31145;"
        >
          Detail Pegawai
        </v-card-title>
        <v-card-text class="pt-5">
          <v-img
            v-if="checkFormatImg(detail.user_image_url)"
            class="mx-auto"
            :aspect-ratio="1 / 1"
            :width="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            :height="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            style="position: relative; z-index: 1;"
            contain
            v-show="detail.user_image_url"
            :src="detail.user_image_url"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height justify-center ma-0" align="center">
                <v-progress-circular indeterminate color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-img
            v-else
            class="mx-auto circle"
            :aspect-ratio="1 / 1"
            :width="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            :height="$vuetify.breakpoint.name === 'xs' ? 150 : 200"
            style="position: relative; z-index: 1; border-radius: 50px; border: 8px solid #ecf0f1"
            contain
            v-show="detail.user_image_url"
            :src="detail.user_image_url"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height justify-center ma-0" align="center">
                <v-progress-circular indeterminate color="#d31145">
                </v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="mt-10">
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Nama
              </p>
              {{ detail.user_name }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Username
              </p>
              {{ detail.user_username }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Email
              </p>
              {{ detail.user_email }}
            </v-list-item-title>
            <v-list-item-title class="body-2 grey--text text--darken-2 mt-2">
              <p class="caption grey--text text-lighten-4 mb-1">
                Nomor Handphone
              </p>
              {{ detail.user_mobilephone }}
            </v-list-item-title>
          </div>
        </v-card-text>
        <v-card-actions class="px-5 py-3">
          <v-spacer />
          <v-btn
            small
            outlined
            color="#d31145"
            class="text-capitalize"
            @click="dialog.detail = false"
          >
            <v-icon color="#d31145" small class="mr-1"
              >mdi-close-circle</v-icon
            >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG GANTI STATUS ==== -->
    <v-dialog v-model="dialog.status" persistent max-width="400">
      <v-card class="pa-3">
        <v-card-title class="title">Peringatan !</v-card-title>
        <v-card-text>
          Apakah anda yakin ingin mengirim ulang undangan untuk {{ detail.email }} ini ?
        </v-card-text>
        <v-card-text class="mt-5 pb-0 px-0">
          <v-alert
            type="error"
            text
            dense
            prominent
            v-show="response.length > 0"
            v-html="response"
          >
          </v-alert>
        </v-card-text>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            small
            width="70"
            color="#d31145"
            elevation="0"
            :disabled="process.form"
            :loading="process.form"
            class="white--text text-capitalize"
            @click="resendInvitation()"
          >
            Yakin
          </v-btn>
          <v-btn
            small
            width="70"
            elevation="0"
            class="text-capitalize"
            :disabled="process.form"
            @click="dialog.status = false"
          >
            Tidak
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DIALOG FORM ==== -->
    <v-dialog
      v-model="dialog.form"
      persistent
      scrollable
      width="800">
      <v-card class="rounded-lg">
        <v-card-title class="subtitle-1">
          {{ dialog.form_type == "add" ? "Undangan Baru" : "Ubah Akun Sales" }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              dialog.form = false;
              $refs['validation-new-invitation'].reset();">
            <v-icon color="#e74c3c">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-subtitle class="caption">
          {{ dialog.form_type == "add" ? "Tambahkan undangan baru kepada Merchant" : "Ubah akun sales" }}
          <v-scroll-y-transition>
            <v-alert
              dense
              outlined
              text
              icon="mdi-alert-circle-outline"
              color="#e74c3c"
              class="mb-0 mt-2"
              v-show="feedback.length > 0"
            >
              <div class="subtitle-2">{{ feedback }}</div>
            </v-alert>
          </v-scroll-y-transition>
        </v-card-subtitle>

        <v-divider></v-divider>

        <v-card-text class="pa-4">
          <ValidationObserver ref="validation-new-invitation">
            <v-form @:keyup.enter="save(dialog.form_type)">
              <v-combobox
                outlined
                dense
                hide-selected
                multiple
                small-chips
                attach
                v-model="form.email_array"
                color="#d31145"
                class="mt-2"
                placeholder="Tuliskan email Merchant yang ingin Anda undang untuk bergabung dalam franchise"
                :search-input.sync="search_email">
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item"
                    v-bind="attrs"
                    color="grey lighten-1"
                    :input-value="selected"
                    small>
                    <span class="pr-2">
                      {{ item }}
                    </span>
                    <v-icon
                      small
                      @click="parent.selectItem(item)">
                      mdi-close
                    </v-icon>
                  </v-chip>
                </template>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Tekan <kbd>enter</kbd> untuk menambahkan "<strong>{{ search_email }}</strong>"
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-form>
          </ValidationObserver>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#d31145"
            depressed
            class="white--text text-none"
            :disabled="process.run"
            :loading="process.run"
            @click="save(dialog.form_type)"
          >
            <v-icon left>mdi-check-circle</v-icon>
            Simpan Data
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ==== DATA PEGAWAI ==== -->
    <v-row class="justify-space-between mt-0">
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <div class="text--secondary mb-2" :class="$vuetify.breakpoint.name === 'xs' ? 'body-1 font-weight-bold' : 'title'">
          Data Invitation
        </div>
        <div class="d-flex align-center">
          <v-btn
            depressed
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#d31145"
            @click="actions(null, 'add')"
            class="white--text text-capitalize mb-3"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Tambah</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-plus</v-icon>
          </v-btn>
          <!-- <Export
            class="mb-3 ml-2 mr-2"
            :file_type="'xls'"
            :btnText="'Export Excel'"
            :btnIcon="'mdi-microsoft-excel'"
            :btnColor="'teal'"
            :url="'user/export'"
            :disabled="$store.state.loading"
            :title="'Data-Pegawai'"
            ref="base-export"
            v-on:onClickExport="responseExport"
          />
          <v-btn
            depressed
            v-if="$vuetify.breakpoint.name !== 'sm'"
            :fab="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :x-small="$vuetify.breakpoint.name !== 'xs' ? false : true"
            :width="$vuetify.breakpoint.name === 'xs' ? '' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize mb-3"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            <span v-if="$vuetify.breakpoint.name !== 'xs'">Filter</span>
            <v-icon v-if="$vuetify.breakpoint.name === 'xs'">mdi-filter-variant</v-icon>
          </v-btn> -->
        </div>
        <!-- <div v-if="$vuetify.breakpoint.name === 'sm'">
          <v-btn
            depressed
            :width="$vuetify.breakpoint.name === 'xs' ? '100%' : '150'"
            elevation="0"
            :disabled="process.run"
            color="#aaa9ad"
            class="white--text text-capitalize mb-3"
            @click="$refs['base-table'].isFilterVisible = true"
          >
            Filter
          </v-btn>
        </div> -->
      </v-col>
      <v-col :cols="$vuetify.breakpoint.name === 'xs' ? 6 : 4">
        <v-text-field
          outlined
          dense
          :hide-details="true"
          v-model="searching"
          placeholder="Cari Data . . ."
          clearable
          color="#d31145"
          @click:clear="searching = ''"
          prepend-inner-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-divider style="display: none;" class="mt-4" v-if="$vuetify.breakpoint.name !== 'xs'"></v-divider>

    <!-- ==== LIST DATA PEGAWAI ====  -->
    <div :class="$vuetify.breakpoint.name !== 'xs' ? 'my-5' : 'mb-5'" style="min-height: calc(100vh - 415px)">
      <v-skeleton-loader
        :loading="process.run"
        transition="scale-transition"
        :tile="false"
        type="table-tbody"
      >
        <base-table
          customHeight="calc(100vh - 350px)"
          :isExternalFilterButton="true"
          :isFilterOnHeader="true"
          v-show="displayBaseTable"
          ref="base-table"
          :filterHeader="filterHeader_clientList"
          itemKey="id"
          :single-select="singleSelect"
          :headers="headers"
          retrieveDataURL="franchise/invite"
          v-on:onRetrieveDataEnd="responseDataEnd"
        >
          <template v-slot:[`item.aksi_table`]="{ item }">
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  min-width="30"
                  class="text-capitalize orange--text text--darken-1 pa-0 mr-1"
                  @click="toDetail(item)"
                >
                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-information-outline</v-icon>
                </v-btn>
              </template>
              <span>Detail Pegawai</span>
            </v-tooltip> -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                  min-width="30"
                  class="text-capitalize red--text pa-0 mr-1"
                  :disabled="item.status == 'accept'"
                  @click="actions(item, 'resend')">
                  <v-icon :size="$vuetify.breakpoint.name === 'xs' || $vuetify.breakpoint.name === 'sm' ? 20 : ''">mdi-email-fast</v-icon>
                </v-btn>
              </template>
              <span>Kirim ulang undangan</span>
            </v-tooltip>
          </template>
          
          <template v-slot:[`item.status`]="{ item }">
            <div
              :class="
                item.status === 'accept'
                  ? 'caption text-capitalize font-weight-bold text-center teal--text'
                  : 'caption text-capitalize font-weight-bold text-center red--text text--darken-2'
              "
            >
              {{ item.status }}
            </div>
          </template>

          <template v-slot:filterTitle>
            Filter Data Pegawai
          </template>
        </base-table>
      </v-skeleton-loader>

      <!-- ==== COMPONENT DATA KOSONG ==== -->
      <Empty v-model="contentEmpty" v-show="emptyData" />
    </div>

    <v-snackbar
      :color="snackbar.color"
      v-model="snackbar.state">
      <div>{{ snackbar.text }}</div>
    </v-snackbar>
  </div>
</template>
<script>
import { get, post, put, destroy } from "@/service/Axios";
import { TokenService } from "@/service/Storage.Service";
import Empty from "@/components/Empty";
import Pagination from "@/components/Pagination";
import Export from "@/components/Export";
export default {
  data() {
    return {
      /**
       * DATA COMPONENT BASETABLE
       */
      displayBaseTable: false,
      emptyData: false,
      singleSelect: true,
      headers: [
        {
          text: "Aksi",
          sortable: false,
          width: "50",
          value: "aksi_table",
          align: "center",
        },
        {
          text: "Email Owner",
          sortable: true,
          width: "150",
          value: "email",
          align: "start",
        },
        {
          text: "Status",
          width: "50",
          align: "center",
          sortable: true,
          value: "status",
        },
      ],
      dataPagination: {
        current: 0,
        limit: 0,
        total_data: 0,
        page: 1,
        itemsPerPage: 10,
      },

      filterHeader_clientList: [
        {
          type: "opsi",
          column: "",
          valueDefault: "",
          label: "",
        },

        {
          type: "text",
          column: "user_name",
          valueDefault: "",
          label: "Berdasarkan Email",
        },
        {
          type: "select",
          column: "user_is_active",
          valueDefault: "",
          label: "Pilih Status",
          items: ["waiting", "accept"],
        },
      ],
      /**
       * END DATA COMPONENT BASETABLE
       */

      color: "",
      response: "",
      searching: "",
      contentEmpty: "",
      detail: {},
      snackbar: {},
      pagination: {
        data: {},
        page: "",
      },
      selected: {
        pagination: 0,
      },
      limit: 10,
      page: 1,
      file: null,
      avatar: null,
      showPage: false,
      form: {
        email_array: []
      },
      search_email: "",
      process: {
        form: false,
        run: false,
      },
      dialog: {
        status: false,
        state: "delete",
        success: false,
        detail: false,
        form: false,
        form_type: "",
        reset: false,
        resetSuccess: false,
      },
      snackbar: {
        show: false,
        color: "",
        text: "",
      },
      list: [],
      group: [],
      response: "",
      feedback: "",
    };
  },
  components: {
    Empty,
    Pagination,
    Export,
  },
  watch: {
    file: {
      handler: function() {
        // this.form.image_url = this.avatar.url
        // this.addImage = this.avatar.add
      },
      deep: true,
    },
    searching: _.debounce(function(newVal) {
      this.searching = newVal;
      this.pagination.page = newVal === null ? "" : 1;
      this.$refs["base-table"].searchVal = newVal;
      this.$refs["base-table"].retrieveData();
      // this.initialize();
    }, 500),
    // 'selected.pagination': function(val) {
    //   this.initialize(val+1)
    // }
  },
  created() {
  },
  computed: {
    user() {
      return JSON.parse(TokenService.getUser());
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    /**
     * HANDLE EXPORT
     */
    responseExport() {
      //send params from base table to base export
      this.$refs["base-export"].params = this.$refs["base-table"].params;

      //KALAU MAU PAKE SEARCH
      // this.$refs["base-export"].params.search = this.$refs[
      //   "base-table"
      // ].searchVal;
    },
    /**
     * END HANDLE EXPORT
     */

    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */
    responseDataEnd(res) {
      this.loading = false;
      this.process.run = false;
      if (res.data.length <= 0) {
        this.emptyData = true;
        this.displayBaseTable = false;
      } else {
        this.emptyData = false;
        this.displayBaseTable = true;
      }
      this.contentEmpty = "Data tidak ditemukan";
    },

    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    /**
     * HANDLE RESPONSE FROM COMPONENT DATATABLE
     */

    checkFormatImg(url) {
      if (url) {
        return this.$options.filters.isPng(url);
      }
    },

    actions (item, type) {
      switch (type) {
        case 'add':
          this.dialog.form = true
          this.dialog.form_type = "add"
          break;
        case 'resend':
          this.response = '' 
          this.detail = item
          this.dialog.status = true;
          this.dialog.state = 'resend';
          break;
      }
    },

    async toDetail(item) {
      this.detail = item;
      this.dialog.detail = true;
    },
    async resendInvitation() {
      this.response = "";
      this.process.form = true;
      let url = ""
      let data = {}
      if (this.dialog.state == 'resend') {
        url = `franchise/invite/resend`
        data = {
          id: this.detail.id,
        }
      }
      await post(url, {
        data
      })
        .then((response) => {
          let res = response.data;
          if (res.status == 200) {
            this.dialog.status = false;
            this.dialog.success = true;
            this.process.form = false;

            this.selected.pagination = 1;
            this.page = 1;
            this.pagination.page = 1;

            this.$refs["base-table"].retrieveData();
          } else {
            this.process.form = false;
            this.response = res.msg;
          }
        })
        .catch((error) => {
          if (error.response) {
            let errorData = error.response.data;
            let errorCode = error.response.status;
            let errorHeader = error.response.headers;
            this.dialog.status = false;

            if (errorCode === 401) {
              this.$store.state.unauthorization = true;
              this.process.run = false;
              this.process.form = false;
            }
          } else {
            // this.$store.state.errorNetwork = true;
            this.process.run = false;
            this.process.form = false;
          }
        });
    },
    async save(type) {
      let _self = this;

      this.feedback = "";

      const isValid = await this.$refs["validation-new-invitation"].validate();

      if (isValid) {
        this.process.run = true;
        let url = ""
        let data = {}
        if (type == "add") {
          url = `franchise/invite`
          data = {
            email_array: this.form.email_array
          }
        }

        await post(url, {
          data
        })
          .then((response) => {
            let res = response.data
            if (res.status === 200) {
              this.dialog.form = false;
              this.$refs["validation-new-invitation"].reset();

              Object.assign(this.form, {
                email_array: []
              });

              this.feedback = "";

              this.dialog = {
                success: true,
                state: "add"
              }

              // this.$refs["base-table"].retrieveData();
            } else {
              if (
                res.error_code &&
                res.error_code == "error_validation"
              ) {
                this.feedback = "Cek kembali form Anda!";

                this.process.run = false

                if (Object.keys(res.results.data).length > 0) {
                  Object.keys(res.results.data).forEach(function (item) {
                    _self.$refs[`form-${item}`].applyResult({
                      errors: [res.results.data[item]],
                      valid: false,
                      failedRules: {},
                    });
                  });
                }
              } else {
                this.snackbar.show = true;
                this.snackbar.color = "red";
                this.snackbar.text = res.message;
              }
              this.process.run = false
            }
          })
          .catch(function (error) {
            // console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.text = "Terjadi kendala pada server.";
            this.process.run = false;
          });
        this.process.run = false;
      }
    },
  },
};
</script>

<style>
.line:hover {
  border-left: 6px solid #d31145;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  transition: 0.3s;
}
.background-cols {
  background-color: #ffca8e61;
  border: 3px solid #ffc58461;
  border-radius: 10px;
}
.br-20 {
  border-radius: 20px;
}
.pagination {
  position: fixed;
  bottom: 65px;
}
.pagination-center .v-slide-group__content {
  justify-content: center !important;
}
</style>
